<template>
    <div class="index">
        <z-header :curActive="2"></z-header>
        <div class="typeArea">
            <z-title :textEn="`DEVELOPMENT PATH`" :text="`发展历程`" :color="`#867d70`"></z-title>
            <span class="development">{{HistoryData.development}}</span>
        </div>
        <!-- 发展历程 -->
        <div class="development-content">
            <img v-if="screenState" src="../../imgs/history/t1.png">
            <div  class="typeArea">
                <div class="menu">
                    <span :class="menuActive == 0 ? 'menu-cur' : ''" @click="menuActive = 0">民国以前</span>
                    <span :class="menuActive == 1 ? 'menu-cur' : ''" @click="menuActive = 1">民国时期</span>
                    <span :class="menuActive == 2 ? 'menu-cur' : ''" @click="menuActive = 2">新中国时期</span>
                </div>
                <div style="width: 100%;height:410px;box-sizing:border-box; overflow: hidden;border-radius: 4px;background-color: rgba(255, 255, 255, 0.8);margin-top: 15px;">
                    <div class="container" ref="container">
                        <div>
                            <span v-if="screenState"></span>
                            <p v-for="(item,index) in HistoryData.containerText.text1" :key="index">{{item}}</p>
                        </div>
                        <div>
                            <span v-if="screenState"></span>
                            <p v-for="(item,index) in HistoryData.containerText.text2" :key="index">{{item}}</p>
                        </div>
                        <div>
                            <span v-if="screenState"></span>
                            <p v-for="(item,index) in HistoryData.containerText.text3" :key="index">{{item}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 茶马古道 -->
        <div class="typeArea">
            <z-title :textEn="`TEA-HORSE ROAD`" :text="`茶马古道`" :color="`#867d70`" style="padding-top: 50px;"></z-title>
            <div class="teaHorse">
                <img v-if="screenState" src="../../imgs/history/c1.png">
                <img v-else src="../../imgs/history/c1app.png">
                <div>
                    <span v-for="(item,index) in HistoryData.teaHorse" :key="index">{{item}}</span>
                </div>
            </div>
        </div>
        <!-- 茶庄文化 -->
        <div style="width: 100%; background-color: #ebebe1;padding-bottom: 100px;">
            <div class="typeArea">
                <z-title :textEn="`TEA HOUSE CUTURE`" :text="`茶庄文化`" :color="`#867d70`"></z-title>
                <div v-if="screenState" class="teaHouseCuture">
                    <img src="../../imgs/history/c2.png" style="width: 100%;height: 100%;position: absolute;">
                    <p style="top: 0;left: 0;">{{HistoryData.teaHouseCuture.title}}</p>
                    <div style="top: 0;right: 0;">
                        <span>{{HistoryData.teaHouseCuture.text1.span}}</span>
                        <p>{{HistoryData.teaHouseCuture.text1.p}}</p>
                    </div>
                    <div style="bottom: 0;left: 0;">
                        <span>{{HistoryData.teaHouseCuture.text2.span}}</span>
                        <p>{{HistoryData.teaHouseCuture.text2.p}}</p>
                    </div>
                    <div style="bottom: 0;right: 301px;">
                        <span>{{HistoryData.teaHouseCuture.text3.span}}</span>
                        <p>{{HistoryData.teaHouseCuture.text3.p}}</p>
                    </div>
                </div>
                <div v-else class="app-teaHouseCuture">
                    <p>{{HistoryData.teaHouseCuture.title}}</p>
                    <div>
                        <div><img src="../../imgs/history/c4app.png"></div>
                        <div class="text">
                            <span>{{HistoryData.teaHouseCuture.text1.span}}</span>
                            <p>{{HistoryData.teaHouseCuture.text1.p}}</p>
                        </div>
                    </div>
                    <div>
                        <div class="text">
                            <span>{{HistoryData.teaHouseCuture.text2.span}}</span>
                            <p>{{HistoryData.teaHouseCuture.text2.p}}</p>
                        </div>
                        <div><img src="../../imgs/history/c2app.png"></div>
                    </div>
                    <div>
                        <div><img src="../../imgs/history/c3app.png"></div>
                        <div class="text">
                            <span>{{HistoryData.teaHouseCuture.text3.span}}</span>
                            <p>{{HistoryData.teaHouseCuture.text3.p}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import zHeader from "../../components/header.vue"
import zTitle from "../../components/z-title.vue"
import HistoryData from "./data"
export default {
    components:{zHeader,zTitle},
    data(){
        return{
            menuActive:0,
            screenState:false,
            HistoryData:{}
        }
    },
    created(){
		// 初始化获取浏览器窗口大小
		let screenWidth = document.body.clientWidth;
		// 根据浏览器窗口大小判断是否显示页面内的部分内容
		if(screenWidth<1200){
			this.screenState=false;
		}else{
			this.screenState=true;
		}
        this.HistoryData = HistoryData
	},
    watch:{
        menuActive(newVal,oldVal){
            console.log(newVal,oldVal,this.screenState)
            //0,1,2
            if(newVal > oldVal){   //2,1  2,0  1,0
                if(newVal == 1){
                    if(this.screenState){
                        this.$refs.container.style.transform = "translateX(-1200px)"
                    }else{
                        this.$refs.container.style.transform = "translateX(-100vw)"
                    }
                }else{
                    if(this.screenState){
                        this.$refs.container.style.transform = "translateX(-2400px)"
                    }else{
                        this.$refs.container.style.transform = "translateX(-200vw)"
                    }
                    
                }
            }else{  // 0,1  0,2  1,2 
                if(newVal == 0){
                    this.$refs.container.style.transform = "translateX(0px)"
                }else{
                    if(this.screenState){
                        this.$refs.container.style.transform = "translateX(-1200px)"
                    }else{
                        this.$refs.container.style.transform = "translateX(-100vw)"
                    }
                }
            }
            
        }
    }
}
</script>
<style scoped>
    .index{
        padding-bottom: 100px;
    }
    .index .typeArea{
        display: flex;
        flex-direction: column;
    }
    .development{
        margin-top: 40px;
        font-size: 14px;
        color: #333333;
        line-height: 28px;
    }
    .development-content{
        width: 100%;
        height: 580px;
        margin-top: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .development-content>img{
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .development-content > .typeArea{
        position: absolute;
        z-index: 9;
    }
    .development-content > .typeArea > .menu{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        background-color: #fff;
    }
    .development-content > .typeArea > .menu{
        border-radius: 4px;
        overflow: hidden;
    }
    .development-content > .typeArea > .menu >span{
        display: block;
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: #594131;
        line-height: 40px;
        cursor: pointer;
    }
    .menu-cur{
        background-color: #d6ab7e !important;
        color: #fff !important;
    }
    .development-content  .container{
        width: 300%;
        height: 410px;
        display: flex;
        flex-direction: row;
        transition: all 0.5s linear;
        box-sizing: border-box;
        font-size: 14px;
    }
    .development-content  .container >div{
        width: 100%;
        padding: 50px 48px 50px;
        box-sizing: border-box;
    }
    .development-content  .container > div > span{
        border-top: 2px solid #705a53;
        display: block;
        width: 50px;
    }
    .development-content  .container > div > p{
        margin-top: 30px;
        line-height: 28px;
        transform: translateX();
    }
    .development-content  .container > div > p:nth-child(2){
        margin-top: 15px;
    }
    /* 茶马古道 */
    .teaHorse{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 50px 0;
    }
    .teaHorse > img{
        width: 48%;
        height: 300px;
        border-radius: 4px;
    }
    .teaHorse > div{
        width: 48%;
    }
    .teaHorse > div > span{
        font-size: 15px;
        color: #666666;
        line-height: 28px;
        margin-top: 10px;
        display: block;
    }
    .teaHorse > div > span:last-child{
        margin-top: 20px;
        display: block;
    }
    /* 茶庄文化 */
    .teaHouseCuture{
        width: 100%;
        height: 400px;
        position: relative;
    }
    .teaHouseCuture > p,
    .teaHouseCuture > div{
        position: absolute;
        z-index: 9;
    }
    .teaHouseCuture > p{
        width: 601px;
        height: 200px;
        padding: 40px;
        box-sizing: border-box;
        font-size: 14px;
        color: #fff;
        line-height: 28px;
    }
    .teaHouseCuture > div{
        width: 301px;
        height: 200px;
        padding: 35px;
        box-sizing: border-box;
    }
    .teaHouseCuture > div >span{
        font-size: 16px;
    }
    .teaHouseCuture > div > p{
        font-size: 14px;
        color: #666;
        line-height: 28px;
        margin-top: 20px;
    }
    @media only screen and (max-width:1199px) {
        .typeArea{
            width: 100%;
            overflow: hidden;
        }
        .development{
            margin: 0;
            padding: 20px;
            line-height: 28px;
            font-size: 14px;
            color: #fff;
            background-image: url('../../imgs/history/b1.png');
            background-size: 100% 100%;
        }
        .development-content{
            margin: 0;
            padding: 25px 20px;
            box-sizing: border-box;
            overflow: hidden;
            width: 100%;
            background-color: #9e9184;
            height: 840px;
            align-items: flex-start;
        }
        .development-content > .typeArea{
            padding: 0 0 25px;
            overflow: hidden;
            box-sizing: border-box;
            height: 100%;
        }
        .development-content > .typeArea > .menu{
            margin-top: 0px;
            width: 90%;
        }
        .development-content > .typeArea>div:nth-child(2){
            height: 750px !important;
            background-color: transparent !important;
            color: #fff;
        }
        .development-content .container >div{
            padding: 0;
            width: 100vw;
            padding: 0 20px;
        }
        .development-content .container > div > p{
            margin-top: 15px;
            font-size: 14px;
        }
        .development-content .container{
            width: 300vw;
        }
        .teaHorse{
            margin: 0;
            flex-direction: column;
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            overflow: hidden;
        }
        .teaHorse > img{
            width: 100%;
            height: auto;
        }
        .teaHorse > div{
            width: 100%;
            font-size: 14px;
            color: #333;
            line-height: 28px;
        }
        .teaHorse > div > span{
            margin-top: 20px;
        }
        .teaHorse > div > span:last-child{
            margin: 10px 0 30px;
        }
        .app-teaHouseCuture{
            line-height: 28px;
            font-size: 14px;
            padding: 0 18px 0 20px;
            display: flex;
            flex-direction: column;
        }
        .app-teaHouseCuture > p{
            margin-bottom: 30px;
        }
        .app-teaHouseCuture>div{
            width: 100%;
            display: flex;
            background-color: #fff;
        }
        .app-teaHouseCuture>div>div{
            width: 50%;
            overflow: hidden;
        }
        .app-teaHouseCuture>div>div img{
            width: 100%;
            height: 100%;
        }
        .app-teaHouseCuture .text{
            padding: 20px 18px 0;
            box-sizing: border-box;
            font-weight: 500;
        }
        .app-teaHouseCuture .text span{
            font-size: 13px;
        }
        .app-teaHouseCuture .text p{
            font-size: 10px;
            color: #666;
            line-height: 20px;
        }
        .index{
            padding: 0;
        }
    }
</style>