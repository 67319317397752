module.exports = {
    development:"西双版纳傣族自治州勐海县是中国第一产茶大县，也是云南普洱茶最大生产地，茶叶是勐海各民族特别是山区少数民族经济收入的重要来源。勐海地理位置和生态环境有利于茶树自然生长并形成丰富的品种资源和优良的茶叶品质。由于具有独特的“勐海味”，勐海普洱茶自古至今备受广大普洱茶消费者青睐。普洱茶是勐海最古老、最具有代表性的文明符号。普洱茶产业在勐海历经千年、传承不息，其发展可分为三个时期：民国以前、民国时期和新中国时期。",
    containerText:{
        text1:[
            "民国以前的勐海普洱茶生产是一个长达1600多年的“散收，无采造法”时期。勐海是世界茶树原产地的中心地带，远古时期的先民们，在长途迁移及采集食物的过程中，逐渐认识并开始利用野生茶树叶。在认识了茶叶的食用及药用价值之后，先民们开始有意识地驯化、栽培利用茶树。同时，为了贮藏及对外交换的方便，先民们利用阳光来晒干茶叶，从而迈出了普洱茶生产发展的第一步。",
            "唐宋时期，勐海县属于云南地方政权南诏、大理国管辖范围。这段时期，勐海普洱茶生产逐渐发展起来，由于当时被统治民族，必须以当地土特产品向南诏统治民族缴纳贡赋，茶叶便是其中之一。那时期被称之为的“银生茶”就是后来 “普洱茶”的前身。",
            "明代是普洱茶的创新发展时期，普洱茶生产技术有了新的突破，出现了揉制茶和压制茶，即通常所说的各类普洱紧压茶。但在勐海茶区，由于地理及社会环境等因素，普洱茶生产仍然是“散收，无采造法”。明初，滇南的步日部改名为 “普耳（洱）”，勐海等地的茶叶由于集中在普洱再加工并集散贸易，故正式得名 “普洱茶”。清代是普洱茶的第一个繁荣兴盛时期。普洱茶列为贡茶进入京城，进入皇宫，同时还大量运销海内外，赢得了普遍的赞誉。"
        ],
        text2:[
            "清末民初，由于战争、疾病及社会治安混乱等诸多因素的影响，西双版纳通往内地及西藏的茶马古道运输不畅，普洱贡茶被迫取消，藏销普洱茶也逐渐减少。普洱茶总体出现了衰落的趋势，古六大茶山部分地区已经衰落。但是，藏区及海外侨胞对普洱茶的需求依然旺盛。在此背景下，加工技术向原料产地的传播及普洱茶运销新通道的开辟，成为普洱茶发展的必然选择。勐海普洱茶也因此在民国时期迅速崛起，各种普洱紧压茶的加工和贸易均呈现出繁荣景象，茶叶加工技术及产量均处于全省领先水平。",
            "1910年，勐海建立第一个茶叶加工作坊茶庄，并从思茅请来揉茶师，收购晒青毛茶，就地加工普洱紧压茶。勐海普洱茶发展从此进入“茶庄时代”。1938年，云南省财政厅委派白孟愚到车佛南茶区成立了 “云南省思普区茶业试验场”（即今省茶叶研究所的前身），1940年勐海建立中茶公司佛海实验茶厂（今勐海茶厂的前身）。",
            "民国时期，勐海还拥有通畅、便捷的普洱茶运销渠道。一方面，勐海是滇藏茶马古道的起点站。另一方面，勐海与缅甸接壤，与泰国为邻，具有通往东南亚、南亚国家的区位优势。经过漫长的历史发展，至20世纪30年代，勐海已成为普洱茶的原料中心、加工中心及集贸中心，茶产业的迅速崛起实属必然。"
        ],
        text3:[
            "1949年，勐海茶业处于历史发展的最低谷。20世纪50年代以来，党和人民政府十分重视边疆茶叶生产的恢复与发展工作，给予了大量的人力、物力、财力支持。1952年，勐海茶厂恢复重建。之后省茶叶研究所对普洱茶的传统加工方法进行了调查研究，总结经验，改进工艺，并在勐海茶区进一步推广生产。20世纪60年代中期，勐海茶厂在生产紧压茶时开始进行人工后发酵试验，产品当时称之为 “云南青”，即现代普洱茶的雏形。",
            "1975年，勐海茶厂开始大批量生产人工后发酵的现代普洱茶，是国内最早生产现代普洱茶的厂家。勐海县独特的地理、气候环境，非常适合普洱茶发酵微生物的生成和繁衍，加上成熟的人工后发酵工艺技术，使勐海成为现代普洱茶最大、最佳的生产地。",
            "2016年“中国•勐海茶研究中心”挂牌成立，从此勐海县人民政府与省茶叶研究所双方战略合作进入了一个新的高度。至20世纪90年代，勐海已发展成为云南省第一产茶大县。2011年9月，勐海县委、县政府提出把勐海县打造成为“中国普洱茶第一县” 的战略目标，全力把勐海县建设成为全国最大、最优、最安全的普洱茶生产加工基地。2018年，拥有涉茶国家地理标志证明商标17件，中国驰名商标4件的勐海县，率先成为全国第一个开展茶产品质量溯源监管公共建设和第一个按照国家标准实现茶产品质量溯源的普洱茶产茶县。"
        ]
    },
    teaHorse:[
        "茶马古道，是祖先留给我们的宝贵财富，是千百年来云南与内地、西藏及国外进行经济文化交流的重要通道。是中华民族繁荣昌盛的历史见证，也是中华文明的重要象征之。它是云南的，也是中国的，更是全世界的，是人类共同的财富。",
        "这些通道由一条条崎岖的古山道、古驿道及一个个城镇、村寨、驿站互相连接、延伸、发展形成，主要路段还用青石块、青石板铺设。道路在历史上主要由马帮承担运输任务，运输的物资从唐代开始以茶叶最为大宗，因此称之为“茶马古道”。"
    ],
    teaHouseCuture:{
        title:"茶庄主要是指加工、经营各类普洱紧压茶的家庭作坊，一般都有自己的名号，故又叫茶号。勐海茶庄起步较晚，但发展很快，产量较高。由于勐海茶叶品质独特，且原料充足，价格相对低廉，制茶成本低，输出也较为便利，因此吸引了众多的茶商开设茶庄，促进了茶业的繁荣。",
        text1:{
            span:"商贸文化",
            p:"商贸往来促进了经济的发展，各民族文化的交流与融合、了解和团结，促进了边疆民族地区的稳定。"
        },
        text2:{
            span:"建筑文化",
            p:"勐海老茶庄起步较晚，属于“汉式四合院”及“傣族干栏式”两种建筑风格并存的文化特色。"
        },
        text3:{
            span:"品牌文化",
            p:"拥有名号的茶庄，依托特定原料及加工方式，产品品质、包装、内飞等都有属于自己的品牌文化。"
        }
    }
}